import React, { useEffect } from "react";
import HomepageLayout from "src/layouts/homepage";
import "./dos.css"; // Import CSS file here

export default function DoNotSellInfo() {
    return (
        <HomepageLayout>
                <iframe src="https://app.termly.io/notify/2da68f1e-73e5-46ad-ac16-e47d3b50abce" title="description"></iframe>
        </HomepageLayout>
    );
}
